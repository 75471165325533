<template>
  <UiSidePanelForm
    :model-value="modelValue"
    :title="`Set ${role?.name} role`"
    :description="role?.description"
    primary-button-text="Save"
    secondary-button-text="Cancel"
    :disabled="loading"
    @update:model-value="cancel"
    @confirm="submit"
  >
    <form class="h-full" @submit.prevent>
      <div class="mb-4">{{ `Select from the list and set user as ${role?.name}.` }}</div>
      <div class="mb-9 flex items-end gap-4" :class="{ '!flex-col': isMarketer }">
        <UiInputSelect
          v-model="data.temporaryUserIds"
          :items="usersItems"
          multiple
          avatar
          name="Users"
          label="Users"
          placeholder="Search or add from the list"
          :error="useGetFieldErrors(v$, ['temporaryUserIds'])"
        />
        <UiButtonBase v-if="!isMarketer" id="add_users" :disabled="!data.temporaryUserIds.length" @click="addUsers"
          >Set</UiButtonBase
        >
        <template v-else>
          <UiInputTextField v-model="data.marketer_id" label="ID" name="ID" placeholder="Set marketer ID" />
          <UiButtonBase
            id="add_marketer"
            :disabled="!data.temporaryUserIds.length || !data.marketer_id"
            @click="addMarketers"
            >Set Marketer</UiButtonBase
          >
        </template>
      </div>
      <UiStickyColumnsTable v-if="data.userIds.length" :items="usersInList" show-action :columns="columns">
        <template #action="{ item }">
          <transition name="fade" mode="out-in">
            <UiIcon name="trash" @click="removeAgent(item)" />
          </transition> </template
      ></UiStickyColumnsTable>
    </form>
  </UiSidePanelForm>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, requiredIf } from '@vuelidate/validators'
import { ROLES } from '~/constants'
import type { InputItem, Role, User } from '@/types'
import { useUiStore } from '~/store/ui'

const uiStore = useUiStore()

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  role: Role
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const loading = ref(false)
const usersItems = ref<InputItem[]>([])

let users: User[] = []

onNuxtReady(() => getUsers())

const data = ref({
  userIds: [],
  temporaryUserIds: [],
  marketer_id: '',
})

const columns = [
  { text: 'User Name', value: 'name' },
  { text: 'Role', value: 'role.name' },
]

const isMarketer = computed(() => props.role?.code === ROLES.MARKETER)

if (isMarketer.value) {
  columns.splice(1, 1, { text: 'ID', value: 'marketer_id' })
}

const getUsers = async () => {
  users = await useGetUsers()

  usersItems.value = users
    .filter((u: User) => !u.teams?.length && ![ROLES.ADMIN, props.role?.code].includes(u.role?.code))
    .map((u: User) => ({ text: u.name, value: u.id, ...u }))
  if (props.role?.code === ROLES.SDR) {
    usersItems.value = usersItems.value.filter((u: User) => u.role?.code === ROLES.AGENT)
  }
  if (isMarketer.value) {
    data.value.userIds = users
      .filter((u) => u.role?.code === props.role?.code)
      .map((u) => ({ id: u.id, marketer_id: u.marketer_id, name: u.name }))
  } else {
    data.value.userIds = users.filter((u) => u.role?.code === props.role?.code).map((u) => u.id)
  }
}

const rules = computed(() => ({
  userIds: { required: helpers.withMessage('At least one user is required', required) },
  temporaryUserIds: {
    requiredIfValue: helpers.withMessage('At least one agent required', requiredIf(!data.value.userIds.length)),
  },
}))

const v$ = useVuelidate(rules, data.value)

const addUsers = () => {
  data.value.userIds?.push(...data.value.temporaryUserIds)
  data.value.temporaryUserIds = []
}

const addMarketers = () => {
  data.value.temporaryUserIds.forEach((id) => {
    const name = users.find((u) => u.id === id)?.name
    data.value.userIds?.push({ name, id, marketer_id: data.value.marketer_id })
  })
  data.value.temporaryUserIds = []
  data.value.marketer_id = ''
}

const removeAgent = (user: User) => {
  if (isMarketer.value) {
    data.value.userIds = data.value.userIds.filter((u) => u.id !== user.id)
  } else {
    data.value.userIds = data.value.userIds.filter((id) => id !== user.id)
  }
}

const usersInList = computed(() => {
  if (isMarketer.value) return data.value.userIds

  return users.filter((u) => data.value.userIds?.includes(u.id))
})

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (isValid) {
    loading.value = true
    try {
      if (isMarketer.value) {
        const payload = {
          users: data.value.userIds.map((u) => ({ user_id: u.id, marketer_id: u.marketer_id.toString() })),
        }
        await useSetMarketerRole(payload)
      } else {
        await useSetUsersRoles(data.value.userIds, props.role.id)
      }
      emits('input', data.value)
      emits('update:modelValue', false)
      // Update users cache
      useGetUsers(true)
    } catch (error: any) {
      uiStore.showSnackBanner(error.message, 'error')
    } finally {
      loading.value = false
    }
  }
}

const cancel = () => {
  data.value = {
    userIds: [],
    temporaryUserIds: [],
    marketer_id: '',
  }
  emits('update:modelValue', false)
}
</script>

<style scoped></style>
